
import * as storyblokRuntime$3qLdOGtbcA from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as noneRuntime$AlJCu1LSrU from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [
    "localhost:3030",
    "beyondbreath.com",
    "a.storyblok.com",
    "assets.beyondbreath.com"
  ],
  "alias": {
    "/assets": "https://assets.beyondbreath.com",
    "/storyblok": "https://a.storyblok.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$3qLdOGtbcA, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['none']: { provider: noneRuntime$AlJCu1LSrU, defaults: {} }
}
        